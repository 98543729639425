import { IconButton } from '@mui/material'
import { Close as IconClose } from '@mui/icons-material'
import { SnackbarKey, useSnackbar } from 'notistack'
import * as React from 'react'

function SnackbarCloseButton({ id }: { id: SnackbarKey }) {
    const { closeSnackbar } = useSnackbar()

    return (
        <IconButton onClick={() => closeSnackbar(id)}>
            <IconClose />
        </IconButton>
    )
}

export default SnackbarCloseButton
