import * as React from 'react'
import * as ResultTemplate from '../templates/Result'
import { useLocation, useNavigate } from 'react-router-dom'

export const Component = () => {
    const location = useLocation()
    const [locationState] = React.useState<{ url: string }>(
        location.state as { url: string }
    )
    const navigate = useNavigate()

    React.useEffect(() => {
        if (locationState === null) {
            navigate('/')
            return
        }
    }, [locationState, navigate])

    if (!locationState) return <></>

    return (
        <ResultTemplate.Component
            openSeaUrl={'https://opensea.io/collection/bluebirds-eth'}
            txUrl={locationState.url}
        />
    )
}
