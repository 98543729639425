import * as React from 'react'
import * as MainLayout from '../layouts/Main'
import {
    Typography,
    CardMedia,
    Backdrop,
    CircularProgress,
    Stack,
    Container,
    Button
} from '@mui/material'
import Logo from '../assets/egg.gif'

type Props = {
    loading: boolean
    onClickMint: () => void
    setLoading: (flag: boolean) => void
}

export const Component = (props: Props) => {
    return (
        <MainLayout.Component>
            <Backdrop
                sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
                open={props.loading}
                onClick={() => props.setLoading(false)}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Container>
                <Stack
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                >
                    <CardMedia
                        component="img"
                        alt="logo"
                        src={Logo}
                        sx={{
                            marginTop: '-100px',
                            width: {
                                xs: '100vw',
                                sm: '100vw',
                                md: '70vh'
                            },
                            imageRendering: '-webkit-optimize-contrast',
                            cursor: 'pointer'
                        }}
                        onClick={props.onClickMint}
                    />
                    <Typography
                        variant={'h3'}
                        fontFamily={'DotGothic16'}
                        sx={{
                            fontSize: {
                                xs: '24px',
                                sm: '24px',
                                md: '42px'
                            }
                        }}
                    >
                        Click the egg to hatch!!
                    </Typography>

                    <Button
                        variant="text"
                        sx={{
                            marginTop: 10,
                            fontSize: {
                                xs: '20px',
                                sm: '20px',
                                md: '36px'
                            },
                            fontFamily: 'DotGothic16',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            window.open(
                                ' https://opensea.io/collection/bluebirds-eth',
                                '_blank'
                            )
                        }}
                    >
                        OpenSea
                    </Button>
                    <Typography
                        variant={'overline'}
                        fontFamily={'DotGothic16'}
                        sx={{
                            fontSize: {
                                xs: '4px',
                                sm: '10px',
                                md: '10px'
                            }
                        }}
                    >
                        version: 0.1 - without head
                    </Typography>
                </Stack>
            </Container>
        </MainLayout.Component>
    )
}
