import * as React from 'react'
import { ethers } from 'ethers'
import { useSnackbar } from 'notistack'
import { BlueBirds__factory, BlueBirds } from '../modules/typechain'
import { isMobileSafari } from 'react-device-detect'

type Provider = ethers.providers.Web3Provider
type Network = ethers.providers.Network
type Props = {
    children: React.ReactNode
}
type MetaMaskContextType = {
    connect: () => Promise<void>
    connected: boolean
    provider: Provider | null
    signer: ethers.Signer | null
    network: Network | null
    address: string | null
    blueBirdsContract?: BlueBirds | null
    loading: boolean
}

declare global {
    interface Window {
        ethereum: any | undefined // metamask injects global ethereum
    }
}

export const MetaMaskContext = React.createContext<MetaMaskContextType>({
    connect: async () => {},
    provider: null,
    signer: null,
    network: null,
    address: null,
    connected: false,
    loading: true
})

export const MetaMaskProvider = ({ children }: Props) => {
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = React.useState<boolean>(true)
    const [connected, setConnected] = React.useState<boolean>(false)
    const [provider, setProvider] = React.useState<Provider | null>(null)
    const [signer, setSigner] = React.useState<ethers.Signer | null>(null)
    const [network, setNetwork] = React.useState<Network | null>(null)
    const [address, setAddress] = React.useState<string | null>(null)
    const [blueBirds, setBlueBirds] = React.useState<BlueBirds | null>(null)

    const connect = async () => {
        try {
            await attemptConnection()
        } catch (error) {
            console.error(error)
            enqueueSnackbar('MetaMask Connection Rejected', {
                variant: 'error',
                anchorOrigin: { horizontal: 'center', vertical: 'top' }
            })
        }
    }

    const attemptConnection = async () => {
        if (window.ethereum === undefined) {
            if (isMobileSafari) {
                // eslint-disable-next-line no-restricted-globals
                location.href = `dapp://${location.hostname}${location.pathname}${location.search}`
                return
            } else {
                if (window.confirm('Open in the MetaMask app?')) {
                    // eslint-disable-next-line no-restricted-globals
                    location.href = `dapp://${location.hostname}${location.pathname}${location.search}`
                    return
                }
            }
            enqueueSnackbar('MetaMask Should Be Installed', {
                variant: 'error'
            })
            return
        }
        window.ethereum!.on('chainIdChanged', () => window.location.reload())
        window.ethereum!.on('chainChanged', () => window.location.reload())
        window.ethereum!.on('accountsChanged', (r: Array<any>) => {
            if (
                // 切り替わったアドレスが現在のアドレスと違う場合、もしくは全てのアカウントが接続解除された場合
                // セッションを破棄して画面をリロードする
                (r &&
                    r.length &&
                    ethers.utils.getAddress(r[0]) !==
                        ethers.utils.getAddress(address)) ||
                (r && r.length === 0)
            ) {
                window.location.reload()
            }
        })

        // get provider, address, and network
        const eProvider = new ethers.providers.Web3Provider(window.ethereum)
        await eProvider.send('eth_requestAccounts', [])
        const eSigner = await eProvider.getSigner()
        const address = await eSigner.getAddress()
        const eNetwork = await eProvider.getNetwork()

        const bd = BlueBirds__factory.connect(
            process.env.REACT_APP_CONTRACT_ADDRESS as string,
            eSigner
        )

        // set states
        setAddress(address)
        setProvider(eProvider)
        setNetwork(eNetwork)
        setSigner(eSigner)
        setConnected(true)
        setBlueBirds(bd)
        setLoading(false)
    }
    React.useEffect(() => {
        if (window.ethereum) {
            window.ethereum
                .request({
                    method: 'eth_accounts'
                })
                .then(async (r: Array<any>) => {
                    if (r.length > 0) {
                        await connect()
                    } else {
                        setLoading(false)
                        return
                    }
                })
        } else {
            setLoading(false)
        }
    }, [])

    return (
        <MetaMaskContext.Provider
            value={{
                address,
                network,
                provider,
                signer,
                connected,
                connect,
                blueBirdsContract: blueBirds,
                loading
            }}
        >
            {children}
        </MetaMaskContext.Provider>
    )
}
