import 'regenerator-runtime/runtime'
import * as React from 'react'
import { createRoot } from 'react-dom/client';
import { SnackbarProvider } from 'notistack'
import * as Router from './routes'
import { MetaMaskProvider } from './providers/MetaMask'
import './modules/firebase'
import { getAnalytics } from 'firebase/analytics'
import SnackbarCloseButton from './components/SnackbarCloseButton'

getAnalytics()

const App = () => {
    return (
        <SnackbarProvider
            action={key => <SnackbarCloseButton id={key} />}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
            <MetaMaskProvider>
                <Router.Router />
            </MetaMaskProvider>
        </SnackbarProvider>
    )
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
