import * as React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import * as Home from '../pages/Main'
import * as NotFound from '../pages/NotFound'
import * as Result from '../pages/Result'

export const Router = (): React.ReactElement => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home.Component />} />
                <Route path="/result" element={<Result.Component />} />
                <Route path="*" element={<NotFound.Component />} />
            </Routes>
        </BrowserRouter>
    )
}
