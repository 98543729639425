import * as React from 'react'

type Props = {
    children: React.ReactNode
}

export const Component = (props: Props) => {
    return (
        <div>
            {props.children}
        </div>
    )
}
