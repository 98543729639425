import * as React from 'react'
import * as MainLayout from '../layouts/Main'
import { Typography, CardMedia, Stack, Container } from '@mui/material'
import Logo from '../assets/egg.gif'

export const Component = () => {
    return (
        <MainLayout.Component>
            <Container maxWidth={'md'}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <CardMedia
                        component="img"
                        alt="logo"
                        src={Logo}
                        sx={{
                            width: '70vh',
                            'image-rendering': '-webkit-optimize-contrast',
                            cursor: 'pointer'
                        }}
                    />
                    <Typography variant={'h3'} fontFamily={'DotGothic16'}>
                        Not Found
                    </Typography>
                </Stack>
            </Container>
        </MainLayout.Component>
    )
}
