import * as React from 'react'
import * as MainLayout from '../layouts/Main'
import { Typography, Stack, Link, Grid } from '@mui/material'

type Props = {
    txUrl: string
    openSeaUrl: string
}

export const Component = (props: Props) => {
    return (
        <MainLayout.Component>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item xs={3}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Typography variant={'h5'} fontFamily={'DotGothic16'}>
                            A baby Blue Bird is hatching...
                        </Typography>
                        <Typography variant={'h5'} fontFamily={'DotGothic16'}>
                            Please wait for a while and see{' '}
                            <Link
                                href={props.openSeaUrl}
                                underline="hover"
                                target={'_blank'}
                            >
                                OpenSea
                            </Link>
                            {' or check the '}
                            <Link
                                href={props.txUrl}
                                underline="hover"
                                target={'_blank'}
                            >
                                Transaction
                            </Link>
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </MainLayout.Component>
    )
}
