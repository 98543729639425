import * as React from 'react'
import * as MainTemplate from '../templates/Main'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { MetaMaskContext } from '../providers/MetaMask'
import { useSnackbar } from 'notistack'
import { serializeError } from 'eth-rpc-errors'


export const Component = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { address, blueBirdsContract, connect } = useContext(MetaMaskContext)

    const navigate = useNavigate()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [onClicked, setOnClicked] = React.useState<boolean>(false)

    const onClickMint = React.useCallback(async () => {
        setOnClicked(true)
        if (!address) {
            await connect()
        }
        if (!blueBirdsContract) {
            return
        }
        setLoading(true)
        setOnClicked(false)
        try {
            const price = await blueBirdsContract?.fee()
            const tx = await blueBirdsContract.hatch({
                value: price
            })
            const url = `${process.env.REACT_APP_ETHERSCAN_URL}/${tx.hash}`
            navigate('/result', {
                state: { url: url }
            })
        } catch (e) {
            const error = serializeError(e)
            const message = error.message?.length > 50 ? error.message?.slice(0, 50) + '...' : error.message
            enqueueSnackbar(message || 'Something wrong happened!', {
                variant: 'error',
                anchorOrigin: { horizontal: 'center', vertical: 'top' }
            })
        }
        setLoading(false)
        return
    }, [address, blueBirdsContract, connect, enqueueSnackbar, navigate])

    React.useEffect(() => {
        if (blueBirdsContract && onClicked) {
            onClickMint()
        }
    }, [blueBirdsContract, onClickMint, onClicked])

    return (
        <MainTemplate.Component
            loading={loading}
            onClickMint={onClickMint}
            setLoading={setLoading}
        />
    )
}
